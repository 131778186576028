<template>
    <b-overlay :show="isLoading">
        <b-card class="white-bg">
            <b-row>
                <b-col>
                    <b-table-simple responsive bordered striped>
                        <b-thead>
                            <b-tr>
                                <b-th colspan="2">Datos Generales</b-th>
                            </b-tr>
                        </b-thead>
                        <b-tbody>
                            <b-tr>
                                <b-th style="width: 10%">Nombre: </b-th>
                                <b-td>{{ branch.name }}</b-td>
                            </b-tr>
                        </b-tbody>
                    </b-table-simple>
                </b-col>
            </b-row>
            <template #footer>
                <div class="text-right">
                    <b-button variant="outline-secondary" :to="{ name: 'BranchIndex' }" class="mr-2"><font-awesome-icon icon="angle-left"></font-awesome-icon> Regresar</b-button>
                    <b-button variant="secondary" :to="{ name: 'BranchEdit', params: { id: branch.id }}" v-if="branch.id">
                        <font-awesome-icon icon="edit"></font-awesome-icon> Modificar
                    </b-button>
                </div>
            </template>
        </b-card>
    </b-overlay>
</template>

<script>
import { branchUrl } from '@routes';
import * as constants from '@constants';

export default {
    data() {
        return {
            branch: {
                id: 0,
                name: ''
            },
            breadcrumb: {
                title: 'Sucursales',
                path: [
                    {
                        name: 'BranchIndex',
                        text: 'Sucursales'
                    }
                ]
            },
            isLoading: false
        };
    },
    created() {
        this.branch.id = this.$route.params.id ?? 0;

        this.breadcrumb.path.push({
            name: 'BranchShow',
            text: 'Detalle'
        });

        this.$emit('update-breadcrumb', this.breadcrumb);

        this.loadData();
    },
    methods: {
        loadData() {
            this.isLoading = true;

            this.axios.get(branchUrl(this.$route.params.id)).then(response => {
                if (response.data.code === constants.CODE_OK) {
                    let branch = response.data.branch;

                    this.branch.id = branch.id;
                    this.branch.name = branch.name;
                }
            }).finally(() => {
                this.isLoading = false;
            });
        }
    },
    computed: {
    }
};
</script>

<style scoped>

</style>